<template>
    <div class="content-box">
        <div class="container">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>协同管理</el-breadcrumb-item>
                <el-breadcrumb-item>供应链协同</el-breadcrumb-item>
                <el-breadcrumb-item>结果点评</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="button-gutter"></div>

            <el-table :data="tableData" border max-height="600">
                <el-table-column align="center" width="50" label="序号">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="name" align="center" label="订单名称">
      </el-table-column> -->
                <el-table-column prop="orderComment.name" align="center" label="合同"> </el-table-column>
                <el-table-column prop="orderComment.timeCreated" align="center" label="合同建时间"> </el-table-column>
                <el-table-column prop="userInfo.name" align="center" label="合同创建人"> </el-table-column>
                <el-table-column prop="orderComment.totalTask" width="50" align="center" label="任务总量"> </el-table-column>
                <el-table-column prop="orderComment.orderStatus" align="center" label="合同订单状态">
                    <template slot-scope="scope">
                        <span v-show="scope.row.orderComment.orderStatus == 0">待评价</span>
                        <span v-show="scope.row.orderComment.orderStatus == 1">已评价</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderComment.evaluateNum"    align="center" label="合同评价">
                    <template slot-scope="scope"  disabled> 
                        <el-rate disabled show-score v-model="scope.row.orderComment.evaluateNum"></el-rate>
                    </template>
                </el-table-column>

                <el-table-column prop="orderComment.timeDelivery" align="center" label="订单结束时间"> </el-table-column>
                <el-table-column prop="orderComment.evaluateContent" align="center" label="评论"> </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.orderComment.orderStatus == 0"
                            style="margin-right: 12px"
                            size="mini"
                            type="success"
                            @click="Reset(scope.row, scope.$index)"
                        >
                            评价
                        </el-button>
                        <el-button v-else type="success" style="margin-right: 12px" size="mini" disabled> 已评价 </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog title="合同评价" :visible.sync="dialogVisible" :before-close="Cancel">
                <el-form ref="resetFrom" :model="resetFrom" label-width="100px" label-position="left">
                    <el-form-item label="合同名称" prop="name">
                        <el-input
                            disabled
                            v-model="resetFrom.name"
                            placeholder="请输入订单名称"
                            clearable
                            :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="打分" prop="evaluateNum">
                        <div class="evaItem">
                            <el-rate show-score v-model="resetFrom.evaluateNum"></el-rate>
                            <span class="rateText">分</span>
                        </div>
                    </el-form-item>

                    <el-form-item label="评论" prop="evaluateContent">
                        <el-input type="textarea" autosize placeholder="请输入内容" v-model="resetFrom.evaluateContent"> </el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="footer">
                    <el-button @click="Cancel">取 消</el-button>
                    <el-button type="primary" @click="SendData(resetFrom)">确 定</el-button>
                </div>
            </el-dialog>

            <el_pagination :total="total" :num="num" @page-limit="page_limit"></el_pagination>
        </div>
    </div>
</template>

<script>
import { fileDownload, fileList, fileRemove } from '@/api/file';
import { commentSearch, commentDelete, commentReset } from '@/api/document';

export default {
    name: 'ResultsReviews',
    components: {},
    created() {
        this.getFileList();
    },
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            resetFrom: {},
            mode: 'add',
            data: {},
            num: 0,
            total: 0,
            pageSize: 10,
            currentPage: 1
        };
    },
    methods: {
        // 分页子集传过来的数据  page = 页码  limit = 条数
        // 分页子集传过来的数据  page = 页码  limit = 条数
        page_limit(list) {
            console.log(list);
            let { page, limit } = list;
            this.currentPage = page;
            this.pageSize = limit;
            this.tableData = [];
            this.getFileList();
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },

        async getFileList() {
            let limit = this.pageSize;
            let page = this.currentPage;
            let res = await commentSearch({
                limit,
                page
            });
            if (res.code == 200) {
                console.log(res.data);
                this.tableData = res.data;
                this.total = res.total;
                this.num = parseInt(res.total / limit) + 1;
            }
        },

        async deleteFile(row, $index) {
            let id = row.id;

            const res = await commentDelete({
                id
            });
            if (res.code === 200) {
                this.$message.success('删除成功');
            } else {
                this.$message.error('删除失败');
            }
            await this.getFileList();
        },
        async downloadFile(id) {
            const res = await fileDownload(id);
            download(res, 'test');
        },
        //点评弹框
        Reset(row, $index) {
            let data = {
                id: row.orderComment.id,
                name: row.orderComment.name,
                timeCreated: row.orderComment.timeCreated,
                orderUserId: row.orderComment.orderUserId,
                contractId: row.orderComment.contractId,
                orderStatus: row.orderComment.orderStatus,
                totalTask: row.orderComment.totalTask,
                manageId: row.orderComment.manageId,
                other: row.orderComment.other,
                timeDelivery: row.orderComment.timeDelivery
            };
            this.resetFrom = JSON.parse(JSON.stringify(data));
            console.log(this.resetFrom);
            this.dialogVisible = true;
        },
        //点评发送
        async SendData(resetFrom) {
            console.log(this.resetFrom);
            let res = await commentReset(resetFrom);

            if (res.code == 200) {
                this.$message({
                    type: 'success',
                    message: '评价完成'
                });
                this.dialogVisible = false;
            }
            await this.getFileList();
        },
        Cancel() {
            this.dialogVisible = false;
            this.resetFrom = {};
        }
    }
};
</script>

<style lang="scss" scoped>
.button-gutter {
    margin: 20px 0;
}
::v-deep .el-rate {
    line-height: 1;
}
.rateText {
    margin-left: 3px;
}
.evaItem {
    display: flex;
    flex-direction: row;
    align-items: center;
}
::v-deep .el-dialog__header {
    text-align: center;
}
.footer {
    width: 20%;

    margin: 0 auto;
}
</style>